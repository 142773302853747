import React, { Component } from "react";
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';

class Secc_accesos extends Component {
   
    constructor() {
        super();

        this.state = {
            objetos:[],
            div_recorcard: 'hidden',
            response_card:false
        };
    }

    agregaCarrito = () => {
        this.props.showModalCargando();
        var recorcard = document.getElementById("recorcard").value
        this.valida_tarjeta(recorcard);
        var tipo = "";
        var id = document.getElementById("accesos_select").value;

        if(id < 1){
            Swal.fire('Error','Llene todos los campos para continuar','warning');
            this.props.cierraCargando();
            return false;
        }

        if(document.getElementById("nueva").checked){
            tipo = "nueva";
        }
        else if(document.getElementById("preexistente").checked){
            tipo = "preexistente";

            if(recorcard == ""){
                Swal.fire('Error','Llene todos los campos para continuar','warning');
                this.props.cierraCargando();
                return false;
            }
        }
        else if(document.getElementById("cupon").checked){
            tipo = "cupon";
        }

        if(!tipo){
            Swal.fire('Error','Llene todos los campos para continuar','warning');
            this.props.cierraCargando();
            return false;
        }

        let data = {
            token: this.props.token,
            id_acceso: id,
            recorcard: recorcard,
            tipo: tipo,
            costotarjeta: this.props.costotarjeta
        };

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };

        setTimeout(() => {//166-345526-2 TARJETA INCORRECTA

            if(this.state.response_card === false && tipo == "preexistente"){

                Swal.fire('Error','Tarjeta Invalida','warning');
                document.getElementById("recorcard").value = "";
                this.props.cierraCargando();
                return false;

            }
            else{//TARJETA CORRECTA

                fetch(this.props.servurlbase + '/addCarrito.php', requestInfo)
                .then(response => response.json())
                .then(respuesta => {
                    if (respuesta) {
                        this.props.getCarrito(this.props.token);
                        Swal.fire('Artículo agregado a carrito','','success');
                        this.props.cierraCargando();
                    } else {
                        Swal.fire('Error','Upss, algo ocurrió, intente mas tarde','warning');
                        this.props.cierraCargando();
                    }
                })
                .catch(e => console.log(e));
            }
        }, 1500);
    };

    accesos = () => {
        const requestInfo = {
            method: "POST",
            header: new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
        };
        fetch(this.props.servurlbase + "/getAccesos.php", requestInfo)
            .then((response) => response.json())
            .then((objetos) => {
            this.setState({ objetos });                
        })
        .catch((e) => console.log(e));
    };

    checked = () => {

        document.getElementById("recorcard").value = "";

        if(document.getElementById("nueva").checked){
            this.setState({div_recorcard:'hidden'});
        }
        else if(document.getElementById("preexistente").checked){
            this.setState({div_recorcard:'visible'});
        }
        else if(document.getElementById("cupon").checked){
            this.setState({div_recorcard:'hidden'});
        }
    };

    valida_tarjeta = (CGUID)=>{
        let data ={
            CGUID
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };

        fetch(this.props.servurlbase + '/GetCard.php', requestInfo)
        .then(response => response.json())
        .then(detalles => {  

            if(detalles.Code == 0){     
             this.setState({response_card:true});
            }
            else{
             this.setState({response_card:false});  
            }

        })

        .catch(e=>console.log(e));
    };

    defaultCh = () => {
        setTimeout(() => {
            document.getElementById("cupon").checked = true;   
        }, 500);
    };
    
    componentDidMount() {
        this.accesos(); 
        this.defaultCh();   
    };

    render() {
        return (
            <div>
              <div className='container-fluid'>
               <div className="row row_ bg-warning">
                <div className="col-sm-5 col-12 text-center">
                  <br/>
                  <img className="img-fluid contenedor" src={this.props.servurlassets + "recorcard.png"} />
                </div>
                <div className="col-sm-7 col-12">
                    <div className="row">
                      <div className="col-sm-12 col-12">
                        <br/>
                       <h3 className="text-dark fuente">Adquiere tu acceso individual y salta ilimitadamente por un día.</h3>
                      </div>
                      <div className="col-sm-6 col-12">
                       <br/>
                       <h5 className="text-dark fw-bold">Selecciona el día que visitarás UPA</h5>
                        <select className="form-select" id="accesos_select">
                         <option disabled selected value={0}>Seleccione...</option>
                             {this.state.objetos.length > 0
                                    ? this.state.objetos.map((objetos) => (
                                        <option value={objetos.id}>{objetos.descripcion}</option>
                            ))
                        : []}

                        </select>                      
                      </div>   
                      <div className="col-sm-6 col-12"></div>
                      <div className="col-sm-12 col-12 form-check" style={{paddingLeft:'2em'}}>
                        <br/>
                        <input className="form-check-input" type="radio" onClick={this.checked.bind()}  name="radio" value={'preexistente'} id="preexistente"/>
                        <h5 className="text-dark">En tarjeta preexistente</h5>
                      </div>
                      <div className="col-sm-12 col-12 form-check" style={{paddingLeft:'2em'}}>
                        <input className="form-check-input" type="radio" onClick={this.checked.bind()} name="radio" value={'nueva'} id="nueva"/>
                        <h5 className="text-dark">En tarjeta nueva ( + $40.00)</h5>
                      </div>
                      <div className="col-sm-12 col-12 form-check" style={{paddingLeft:'2em'}}>
                        <input className="form-check-input" type="radio" onClick={this.checked.bind()} name="radio" value={'cupon'} id="cupon"/>
                        <h5 className="text-dark">En cupón digital</h5>
                      </div>
                        <p>Si no cuentas con tarjeta, recuerda comprarla en línea.
                        <br/>
                        El costo en UPA será de $65.00 ($40 tarjeta + $25 carga minima).</p>

                      <div className="col-sm-6 col-12" id="div_recorcard" style={{ visibility:this.state.div_recorcard}}>
                        <InputMask mask="999-999999-9" className="form-control" alwaysShowMask={true} maskChar="">
                            {({ inputRef }) => (
                            <input
                                type="text"
                                id="recorcard"
                                className="form-control"
                                maxLength={12}
                                ref={inputRef} // Pasar la referencia del input al InputMask
                            />
                            )}
                        </InputMask>
                      </div>
                    </div>
                </div>
                <div className="col-sm-12 col-12 text-center">
                  <br/>
                  <button type="button" id="btn_add" className="btn btn-light fw-bold text-danger" onClick={this.agregaCarrito.bind()}>Agrega al carrito</button>
                </div>
                <div className="col-sm-12 col-12 text-center">
                 <br/>
                   <a href="#" class="text-decoration-none fw-bold" onClick={this.props.cambiaSeccion.bind(this, "tyc" , "", "")}>Consulta términos y condiciones</a>
                 <br/><br/><br/><br/>
                </div>
            </div>
         </div>
        </div>
        );
    }
}
export default Secc_accesos;