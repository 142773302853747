import React, { Component } from "react";
import "./desktop.css";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import Modal_ubicacion from "./modal_ubicacion";
import Modal_atracciones from "./modal_atracciones";
import { CSSTransition } from 'react-transition-group';
import {  Row, Col, Label} from 'reactstrap';

class Secc_inicio extends Component {
    constructor() {
        super();

        this.state = {
            objetos:[],
            showAtraccion:true,
            arrayAtraccion:[],
            infodet:false,
            objetos2:[],
            objetos3:[],
            objetos_atracciones:[],
            objetos_promociones:[],
            sw_auto: true,
            dropdownOpen: false,
            sucursal:"",
            modal:false,
            modal_atracciones:false,
            objetos_ubicacion:[],
            objetos_atraccion:[]
        };
    }

    toggle() {
        this.setState(prevState => ({
          dropdownOpen: !prevState.dropdownOpen
        }));
    };

    showModal=(objetos_ubicacion) => {
        console.log(objetos_ubicacion);
        this.setState({modal:true});
        this.setState({objetos_ubicacion});
    };

    cierra = () => {
        this.setState({modal:false});
    };

    showModalAtracciones=(atraccion) => {
        this.setState({modal_atracciones:true});
        this.setState({objetos_atraccion:atraccion});
    };

    cierraAtracciones = () => {
        this.setState({modal_atracciones:false});
    };

    carrusel_uno = () => {
        let data = {
            carruselid: 1,
            objetofilterid: "",
        };
        const requestInfo = {
            method: "POST",
            body: JSON.stringify(data),
            header: new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
        };
        fetch(this.props.servurlbase + "/getObjetos.php", requestInfo)
            .then((response) => response.json())
            .then((objetos) => {
                    this.setState({ objetos });                
            })
            .catch((e) => console.log(e));
    }; 

    carrusel_dos = () => {
        let data = {
            carruselid: 2,
            objetofilterid: "",
        };
        const requestInfo = {
            method: "POST",
            body: JSON.stringify(data),
            header: new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
        };
        fetch(this.props.servurlbase + "/getObjetos.php", requestInfo)
            .then((response) => response.json())
            .then((objetos2) => {
                    this.setState({ objetos2 });                
            })
            .catch((e) => console.log(e));
    };

    chan = (fiestaindex)=>{
        this.setState({swimg:fiestaindex});
        this.setState({info:false});
        this.setState({infodet:true});
    }

    carrusel_tres = () => {
        let data = {
            carruselid: 4,
            objetofilterid: "",
        };
        const requestInfo = {
            method: "POST",
            body: JSON.stringify(data),
            header: new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
        };
        fetch(this.props.servurlbase + "/getObjetos.php", requestInfo)
            .then((response) => response.json())
            .then((objetos3) => {
                    this.setState({ objetos3 });                
            })
            .catch((e) => console.log(e));
    };

    atracciones = () => {
      let data = {
          carruselid: 3,
          objetofilterid: "",
      };
      const requestInfo = {
          method: "POST",
          body: JSON.stringify(data),
          header: new Headers({
              "Content-Type": "application/json",
              Accept: "application/json",
          }),
      };
      fetch(this.props.servurlbase + "/getObjetos.php", requestInfo)
          .then((response) => response.json())
          .then((objetos_atracciones) => {
                  this.setState({ objetos_atracciones });                
          })
          .catch((e) => console.log(e));
    };
    
    showAtraccion = (objeto) => {
        this.setState({showAtraccion:false});
        this.setState({arrayAtraccion:objeto});
        console.log(objeto);
    }

    promociones = () => {
      let data = {
          carruselid: 6,
          objetofilterid: "",
      };
      const requestInfo = {
          method: "POST",
          body: JSON.stringify(data),
          header: new Headers({
              "Content-Type": "application/json",
              Accept: "application/json",
          }),
      };
      fetch(this.props.servurlbase + "/getObjetos.php", requestInfo)
          .then((response) => response.json())
          .then((objetos_promociones) => {
                  this.setState({ objetos_promociones });                
          })
          .catch((e) => console.log(e));
    }; 

    componentDidMount() {
        this.carrusel_uno();
        this.carrusel_dos();
        this.carrusel_tres();
        this.atracciones();
        this.promociones();
    };

    render() {
        return (
            <div>
                {/* ------------------------------- PRIMERA SECCION -------------------------------------*/}
                <div className='container-fluid'>
                    <div className="row row_ text-center"  style={{ backgroundImage: `url("https://ecommercegd.blob.core.windows.net/imagenes-u/fprincipal.png")` , backgroundSize:'100%'}}>
                        <div className="col-sm-12 col-12"></div>
                        <div className="col-sm-3 col-12">
                        <img className="img-fluid desapareceimagen jump_nina" src={this.props.servurlassets + "niña.png"}  />
                        </div>
                        <div className="col-sm-6 col-12">
                            <Carousel
                                showDots={false}
                                responsive={this.props.responsive}
                                infinite={true}
                                autoPlay={this.state.sw_auto}
                                autoPlaySpeed={6000}
                                keyBoardControl={true}
                                customTransition="all 1s linear"
                                transitionDuration={500}
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                renderDotsOutside={false}
                                arrows={true}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                            >
                                {this.state.objetos.length > 0? this.state.objetos.map((objetos, index) => (
                                    <div key={index} className="text-center">
                                        <img className="img-fluid" src={this.props.servurlassets + objetos.nombre + objetos.extension} />
                                    </div>
                                )): []}
                            </Carousel>
                        </div>
                        <div className="col-sm-3 col-12">
                        <img className="img-fluid desapareceimagen jump_nino" src={this.props.servurlassets + "nino.png"}  />
                        </div>
                    </div>
                </div>
                {/* ------------------------------- COMPRAS SECCION -------------------------------------*/}
                <div className="container-fluid" id="div_compras">
                    <div className="row" style={{ backgroundImage: `url("https://ecommercegd.blob.core.windows.net/imagenes-u/fondo_trampolines.jpg")` }}>
                        <div className="col-sm-12 col-12 title_precios">
                            <br/><br/><br/>
                            <div className="row">
                                <div className="col-sm-6 col-12">
                                    <h3>PRECIOS Y COMPRAS EN LÍNEA </h3>
                                    <h5>SALTA ILIMITADAMENTE POR TODO UN DÍA</h5>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <br/>
                                    <h5>COMPRA TU ACCESO EN LÍNEA O EN TAQUILLA</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12">
                            <div className="row" style={{paddingLeft:'15%'}}>
                                <div className="col-sm-12 col-12" style={{height:'200px'}}>
                                    <img className="img-fluid" style={{height:'100%'}} src={this.props.servurlassets + "179.png"} />
                                </div>
                                <div className="col-sm-12 col-12" style={{height:'200px'}}>
                                    <img className="img-fluid" style={{height:'100%'}} src={this.props.servurlassets + "199.png"} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12">
                            <Carousel
                                showDots={false}
                                responsive={this.props.responsive}
                                infinite={true}
                                autoPlay={this.state.sw_auto}
                                autoPlaySpeed={6000}
                                keyBoardControl={true}
                                customTransition="all 1s linear"
                                transitionDuration={500}
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                renderDotsOutside={false}
                                arrows={true}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px">
                                {this.state.objetos2.length > 0? this.state.objetos2.map((objetos, index) => (
                                    <div className="text-center row">
                                        <div key={index} className="col-sm-12 col-12 text-center" style={{ height:'70%' }}>
                                            <img style={{ cursor:'pointer' }} width={'50%'} src={this.props.servurlassets + objetos.nombre + objetos.extension} onClick={this.props.cambiaSeccion.bind(this, objetos.url_click , "", "")} />
                                            <br/><button className="btn btn-danger text-light fw-bold" onClick={this.props.cambiaSeccion.bind(this, objetos.url_click , "", "")}>COMPRAR</button>
                                        </div>
                                    </div>
                                )): []}
                            </Carousel>
                            <br/><br/>
                        </div>
                    </div>
                </div>
                {/* ------------------------------- PROMOCIONES SECCION -------------------------------------*/}
                <div className="container-fluid bg-danger" id="div_promociones">
                    <div className="row" style={{ backgroundImage: `url("https://ecommercegd.blob.core.windows.net/imagenes-u/fondo_atracciones.png")` , backgroundSize:'100%'}}>
                        <div className="col-sm-12 col-12 title_promociones">
                            <p>ELEVA LA DIVERSIÓN</p>
                        </div>
                        <div className="col-sm-12 col-12 title_promociones2">
                            <h1>PROMOCIONES</h1>
                        </div>
                        <div className="col-sm-1 col-12"></div>
                        <div className="col-sm-10 col-12">
                            <Carousel
                                showDots={false}
                                responsive={this.props.responsive}
                                infinite={true}
                                autoPlay={this.state.sw_auto}
                                autoPlaySpeed={6000}
                                keyBoardControl={true}
                                customTransition="all 1s linear"
                                transitionDuration={500}
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                renderDotsOutside={false}
                                arrows={true}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px">
                                    {this.state.objetos_promociones.length > 0? this.state.objetos_promociones.map((objetos, index) => (
                                        <div key={index} className="text-center" style={{ cursor:'pointer' }}>
                                            <img className="img-fluid" src={this.props.servurlassets + objetos.nombre + objetos.extension} onClick={this.props.cambiaSeccion.bind(this, objetos.url_click , objetos, "")}  />
                                        </div>
                                    )): []}
                            </Carousel>
                        </div>
                        <div className="col-sm-1 col-12"></div>
                        <div className="col-sm-12 col-12">
                            <br/>
                        </div>
                    </div>
                </div>
                {/* ------------------------------- ATRACCIONES SECCION -------------------------------------*/}
                <div className="container-fluid" id="div_atracciones">
                    <div className="row row_" style={{ backgroundImage: `url("https://ecommercegd.blob.core.windows.net/imagenes-u/fprincipal.png")` , backgroundSize:'100%'}}>
                        <div className="col-sm-12 col-12 title_atracciones">
                            <p>ATRACCIONES</p>
                        </div>
                        <div className="col-sm-12 col-12 text-center" id="div_atracciones">
                            <Carousel
                                showDots={false}
                                responsive={this.props.responsive_atracciones}
                                infinite={true}
                                autoPlay={this.state.sw_auto}
                                autoPlaySpeed={6000}
                                keyBoardControl={true}
                                customTransition="all 1s linear"
                                transitionDuration={500}
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                renderDotsOutside={false}
                                arrows={true}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px">
                                {this.state.objetos_atracciones.length > 0 ? this.state.objetos_atracciones.map((objetos, index) => (
                                    <div key={index} className="text-center">
                                        <img style={{ cursor:'pointer' }} onClick={this.showAtraccion.bind(this,objetos)} width={290} className="rounded-5 contenedor" src={this.props.servurlassets + objetos.nombre + objetos.extension}  />
                                    </div>
                                )): []}
                            </Carousel>
                        </div>
                    </div>
                </div>
                <div style={{backgroundColor:'#F20000'}}>
                    <div hidden={this.state.showAtraccion} id="div_atraccion">
                        <div className="col-sm-6 col-6 text-center" style={{position:'absolute'}}>
                            <p  className="title_atracciones">{this.state.arrayAtraccion.nombre==='zona_ninja'?'Zona Ninja':this.state.arrayAtraccion.nombre}</p>
                            <h3 className="fw-bold text-light" style={{paddingLeft:'5%'}}>{this.state.arrayAtraccion.descripcion}</h3>
                        </div>
                        <img width={'100%'} src={this.props.servurlassets + this.state.arrayAtraccion.img}  />
                    </div>
                </div>
                {/* ------------------------------- SUCURSALES SECCION -------------------------------------*/}
                <div className="container-fluid">
                    <div className="row row_" style={{ backgroundImage: `url("https://ecommercegd.blob.core.windows.net/imagenes-u/fondo-blanco.png")` }}>
                        <div className="col-sm-12 col-12 title_sucursales">
                            <br/>
                            <p>SUCURSALES</p>
                        </div>
                        <div className="col-sm-12 col-12" id="div_sucursales">
                            <Carousel
                                showDots={false}
                                responsive={this.props.responsive_sucursales}
                                infinite={true}
                                autoPlay={this.state.sw_auto}
                                autoPlaySpeed={6000}
                                keyBoardControl={true}
                                customTransition="all 1s linear"
                                transitionDuration={500}
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                renderDotsOutside={false}
                                arrows={true}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px">
                                {this.state.objetos3.length > 0? this.state.objetos3.map((objetos, index) => (
                                    <div key={index} className="text-center">
                                        <div className="row text-center">
                                            <div className="col-sm-12 col-12">
                                                <img width={280} src={this.props.servurlassets + objetos.nombre + objetos.extension}  />
                                            </div>
                                            <div className="col-sm-1 col-12"></div>
                                            <div className="col-sm-10 col-12 text-center" style={{textAlign:'justify',minHeight:'130px'}}>
                                                <h4 className="fw-bold title_sucursal">{objetos.title}</h4>
                                                <h6 className="fw-bold">{objetos.descripcion}</h6>
                                            </div>
                                            <div className="col-sm-1 col-12"></div>
                                            <div className="col-sm-1 col-12"></div>
                                            <div className="col-sm-10 col-12">
                                                <h6 className="fw-bold">{objetos.horario}</h6>
                                            </div>
                                            <div className="col-sm-1 col-12" ></div>
                                            <div className="col-md-12 col-sm-12 col-12">
                                                <button type="button" className="btn btn-danger text-light fw-bold" onClick={this.showModal.bind(this,objetos)}>VER MAPA</button>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                )):[]}
                                
                            </Carousel>
                        </div>
                    </div>
                </div>
                {this.state.modal === true?
                    <Modal_ubicacion cierra = {this.cierra} servurlassets = {this.props.servurlassets} servurlbase = {this.props.servurlbase} objetos_ubicacion = {this.state.objetos_ubicacion}/>
                :''}
                {this.state.modal_atracciones === true?
                    <Modal_atracciones cierraAtracciones = {this.cierraAtracciones} servurlassets = {this.props.servurlassets} servurlbase = {this.props.servurlbase} objetos_atraccion = {this.state.objetos_atraccion}/>
                :''}
            </div>
        );
    }
}
export default Secc_inicio;
