import React, { Component } from 'react';
import {Col, Modal, ModalHeader, ModalBody,Row} from 'reactstrap';
import './desktop.css';
import Swal from 'sweetalert2';

class Modal_bolsa extends Component{

    constructor() {
        super();
        this.state ={
     }

    }

    enviar = () => {
        this.props.showModalCargando();
        var nombre = document.getElementById("nombre").value;
        var correo = document.getElementById("correo").value;
        var telefono = document.getElementById("telefono").value;
        var nacimiento = document.getElementById("nacimiento").value;
        var domicilio = document.getElementById("domicilio").value;
        var puesto = document.getElementById("puesto").value;

        if( !nombre || !correo || !telefono || !nacimiento || !domicilio || !puesto ){
            Swal.fire("Error", "Llene todos los campos", "warning");
            this.props.cierraCargando();
        }
        else{

        let data = {
            cuerpo: "Asunto:<strong>Solicitud de empleo UPA!</strong><br/><br/>Nombre: " + nombre + " <br/>Correo: " + correo + " <br/>Teléfono: " + telefono + " <br/>Fecha de nacimiento: " + nacimiento + " <br/>Domicilio: " + domicilio + " <br/>Puesto deseado: " + puesto,
            email: "contacto@grupodiniz.com.mx",
            asunto: "Solicitud de empleo ¡UPA!"
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.props.servurlbase + '/sendCorreoContacto.php', requestInfo)
        .then((response) => {
            if(response){
                Swal.fire(
                    'Enviado',
                    'Se ha enviado tu solicitud',
                    'success'
                )
                this.props.cierra();
                this.props.cierraCargando();
            }
            else{
                Swal.fire("Error", "Error", "warning");
                this.props.cierraCargando();
            }
        })
        .catch(e => console.log(e));
     }
    }

    render(){
        
        return(
            <div>
                <Modal className='modal-lg' isOpen={true} toggle={this.props.cierra}>
                    <ModalHeader className='bg-light' toggle={this.props.cierra}>
                     <h3 className='fw-bold morado'>Solicitud de empleo</h3>
                    </ModalHeader>
                    <ModalBody  style={{ backgroundColor:'#90e6ff' }} >
                        <Row>
                         <Col className="col-sm-12 col-12">
                         <label><b>Nombre completo</b></label>
                          <input type="text" className="form-control" id='nombre' placeholder="Nombre completo" style={{textAlign:'center'}}/>
                          <br/>
                         </Col>
                         <Col className="col-sm-4 col-12">
                         <label><b>Correo</b></label>
                          <input type="text" className="form-control" id='correo' placeholder="Correo" style={{textAlign:'center'}}/>
                          <br/>
                         </Col>
                         <Col className="col-sm-4 col-12">
                         <label><b>Teléfono</b></label>
                          <input type="number" className="form-control" id='telefono' placeholder="Teléfono" style={{textAlign:'center'}}/>
                          <br/>
                         </Col>
                         <Col className="col-sm-4 col-12">
                            <label><b>Fecha Nacimiento</b></label>
                            <input type="date" className="form-control" id='nacimiento' placeholder="Fecha nacimiento" style={{textAlign:'center'}}/>
                          <br/>
                         </Col>
                         <Col className="col-sm-8 col-12">
                            <label><b>Domicilio Completo</b></label>
                            <input type="text" className="form-control" id='domicilio' placeholder="Domicilio completo" style={{textAlign:'center'}}/>
                          <br/>
                         </Col>
                         <Col className="col-sm-4 col-12">
                            <label><b>Puesto de interés</b></label>
                            <input type="text" className="form-control" id='puesto' placeholder="Puesto de interés" style={{textAlign:'center'}}/>
                          <br/>
                         </Col>
                         <Col className="col-sm-12 col-12 text-center">
                           <button className="btn btn-danger text-light fw-bold" id='btn_enviar' onClick={this.enviar.bind(this)}>Enviar</button>
                          <br/>
                         </Col>
                        </Row>
                    </ModalBody>
                </Modal>    
            </div>
        );
    }    
}

export default Modal_bolsa