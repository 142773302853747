import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import Modal_combos from "./modal_combos";
import Modal_compara_combos from "./modal_compara_combos";

class Secc_combos extends Component {
    
    constructor() {
        super();

        this.state = {
            combos:[],
            combo:[],
            modal:false,
            modal_compara:false
        };
    }

    combos = () => {
        let data = {
            carruselid: 5,
            objetofilterid: "",
        };
        const requestInfo = {
            method: "POST",
            body: JSON.stringify(data),
            header: new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
        };
        fetch(this.props.servurlbase + "/getObjetos.php", requestInfo)
            .then((response) => response.json())
            .then((combos) => {
                    this.setState({ combos });                
            })
            .catch((e) => console.log(e));
    };

    showModal=(objetos) => {
        this.setState({modal:true});
        this.setState({combo:objetos})
    };

    cierra = () => {
        this.setState({modal:false});
    };

    showModalCompara=() => {
        this.setState({modal_compara:true});
    };

    cierraCompara = () => {
        this.setState({modal_compara:false});
    };

    componentDidMount() {
        this.combos();
    };

    render() {
        return (
            <div>
              <div className='container-fluid bg-warning'>
                <div className="row row_">
                    <div className="col-sm-12 col-12 title_promociones">
                        <p>Selecciona la promoción</p>
                    </div>
                    <div className="col-sm-2 col-12 text-center" style={{ cursor:'pointer' }}>
                     <img className="img-fluid desapareceimagen jump_nina" src={this.props.servurlassets + "niña.png"}  />
                    </div>
                    <div className="col-sm-8 col-12">
                            <Carousel
                                showDots={false}
                                responsive={this.props.responsive_combos}
                                infinite={true}
                                autoPlay={this.state.sw_auto}
                                autoPlaySpeed={6000}
                                keyBoardControl={true}
                                customTransition="all 1s linear"
                                transitionDuration={500}
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                renderDotsOutside={false}
                                arrows={true}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                            >
                                {this.state.combos.length > 0
                                    ? this.state.combos.map((objetos, index) => (
                                            <div key={index} className="text-center"  style={{ cursor:'pointer', minHeight:'330px' }}>
                                                <img height={320} className="border border-danger border-1 rounded-2 borde_div_blanco contenedor" onClick={this.showModal.bind(this,objetos)} src={this.props.servurlassets + objetos.nombre + objetos.extension}  />
                                            </div>
                                        ))
                                    : []}
                            </Carousel>
                    </div>
                    <div className="col-sm-2 col-12 text-center" style={{ cursor:'pointer' }}>
                        <br/>
                      <img onClick={this.showModalCompara.bind()} className="img-fluid rounded-2 contenedor" src={this.props.servurlassets + "comparapaq.png"}  />
                    </div>
                    <div className="col-sm-12 col-12">
                        <br/>
                        <br/>
                    </div>
                </div>
              </div>
              {
               this.state.modal === true?
                 <Modal_combos cambiaSeccion = {this.props.cambiaSeccion} servurlassets = {this.props.servurlassets} cierra = {this.cierra} combo = {this.state.combo} showModalCargando = {this.props.showModalCargando} cierraCargando = {this.props.cierraCargando} servurlbase = {this.props.servurlbase} token = {this.props.token} costotarjeta = {this.props.costotarjeta} getCarrito = {this.props.getCarrito}/>
               :''
              }
              {
               this.state.modal_compara === true?
                 <Modal_compara_combos  servurlassets = {this.props.servurlassets} cierraCompara = {this.cierraCompara} servurlbase = {this.props.servurlbase} />
               :''
              }
            </div>
        );
    }
}
export default Secc_combos;
