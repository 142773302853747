import React, { useState, useEffect } from "react";
import {
    initiateSocket, disconnectSocket,
    subscribeToChat, sendMessage
} from './socket';

import { URL_CORESERVICE, URL_ASSETS, URL_SOCKETSERVICE } from './../../config'


export default function Pasarela_santander({ id, producto, precio, respuestaProducto }) {

    const [urlFrame, setUrlFrame] = useState("");
    const [visibleFrame, setVisibleFrame] = useState(false);
    const [classFrame, setClassFrame] = useState("ocultar");

    const [urlWSS, setUrlWSS] = useState("");

    const [respuesta, setRespuesta] = useState();


    useEffect(() => {
        if (urlWSS) {

            initiateSocket(id, urlWSS);
            subscribeToChat((err, data) => {
                if (err) return;
                if (id == data.order) {
                    setRespuesta(data);
                }

            });
            return () => {
                disconnectSocket();
            }

        }

    }, [urlWSS]);

    useEffect(() => {
        if (respuesta) {
            respuestaProducto(respuesta);
        }

    }, [respuesta]);

    useEffect(() => {
        if (id !== 0 && id !== null) {
            const getPayUrlRequest = { method: 'POST', body: `{"id":"${id}","producto":"${producto}","monto":${precio}}` };
           
            fetch(URL_CORESERVICE + '/getPayUrl.php', getPayUrlRequest)
                .then(response => response.json())
                .then(response => {
                    if(response){
                     setUrlFrame(response.urlPay);
                     setVisibleFrame(true);
                    }
                })
                .catch(err => {
                    console.error(err)
                });

            fetch(URL_SOCKETSERVICE + '/Pasarela?orderId=' + id)
                .then(response => response.json())
                .then(response => {
                    setUrlWSS(response.urlSocket);
                })
                .catch(err => console.error(err));

        }
    }, [id]);
    useEffect(() => {
        if (visibleFrame) {
            setClassFrame("mostrar");

        }
    }, [visibleFrame]);

    return (
        <section>
            <div height="auto">
                <iframe allow="encrypted-media;" title="mitec"
                    src={urlFrame}
                    width="100%"
                    height="845px"
                    scrolling="no"
                    className={classFrame}
                />
            </div>


        </section>)
}