import React, { Component } from "react";
import Secc_inicio from "./secc_inicio";
import Secc_combos from "./secc_combos";
import Secc_fiestas from "./secc_fiestas";
import Secc_menu from "./secc_menu";
import Secc_promociones from "./secc_promociones";
import Secc_accesos from "./secc_accesos";
import Secc_aviso from "./secc_aviso";
import Secc_reembolsos from "./secc_reembolsos";
import Secc_tyc from "./secc_tyc";
import { v4 as uuid } from 'uuid';
import { OffCanvas, OffCanvasMenu, OffCanvasBody } from "react-offcanvas";
import { URL_CORESERVICE, URL_ASSETS } from "./../config";
import Cookies from 'universal-cookie';
import Modal_carrito from "./modal_carrito";
import Modal_cargando from "./modal_cargando";
import "./desktop.css";
import Secc_compraWeb from './secc_compraWeb';
import Secc_bolsa from "./secc_bolsa";
import ReactPixel from "react-facebook-pixel-ssr";

class MainDesktop extends Component {

    constructor() {
        super();

        this.state = {
            servurlassets: URL_ASSETS + "/imagenes-u/",
            servurlbase: URL_CORESERVICE,
            codigo: "",
            objetofilterid: "",
            cursecc: "main",
            modal:false,
            modal_cargando:false,
            token: '',
            costoLs: [],
            carritoLs: [],
            costotarjeta: 0,
            total:0
        };

    }

    handleClick() {
        this.setState({ isMenuOpened: !this.state.isMenuOpened });
    };

    cambiaSeccion = (sec, codigo, objetofilterid) => {
        this.setState({ objetofilterid: objetofilterid });
        this.setState({ codigo: codigo });
        this.setState({ cursecc: sec });
    };

    showModal=() => {
        this.setState({modal:true});
    };

    cierra = () => {
        this.setState({modal:false});
    };

    showModalCargando=() => {
        this.setState({modal_cargando:true});
    };

    cierraCargando = () => {
        this.setState({modal_cargando:false});
    };

    goToken = () => {
        var cookies = new Cookies();
        let token = cookies.get('recoToken');
        let uniquetoken = uuid();
        if (token === undefined) {
            let cookies = new Cookies();
            cookies.set('recoToken', uniquetoken, { path: '/' });
            token = cookies.get('recoToken');
        }
        this.setState({ token: token });
        this.getCarrito(token);
    };

    getCarrito = (token) => {
        let data = {
            token: token
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.state.servurlbase + '/getCarrito.php', requestInfo)
            .then(response => response.json())
            .then(carritoLs => {
                if (carritoLs !== null) {
                    this.setState({ carritoLs });
                } 
            })
            .catch(e => console.log(e));
    };

    remplazarToken = () => {
        let cookies = new Cookies();
        let token = cookies.get('recoToken');
        cookies.set('recoToken', undefined, { path: '/' });
        
        let uniquetoken = uuid();
        cookies = new Cookies();
        cookies.set('recoToken', uniquetoken, { path: '/' });
        token = cookies.get('recoToken');
    };

    getCostoTarjeta = () => {
        const requestInfo = {
            method: 'POST',
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.state.servurlbase + '/getCostoTarjeta.php', requestInfo)
            .then(response => response.json())
            .then(costoLs => {
                if (costoLs !== null) {
                    this.setState({ costotarjeta: costoLs[0].costo });
                } else {
                    this.setState({ costotarjeta: 0 });
                }
            })
            .catch(e => console.log(e));
    };

    getCarrito = (token) => {
        let data = {
            token: token
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.state.servurlbase + '/getCarrito.php', requestInfo)
            .then(response => response.json())
            .then(carritoLs => {
                let tot = 0;
                if(carritoLs){
                    this.setState({ carritoLs });
                    carritoLs.forEach(element => {
                        tot = tot + Number(element.monto);
                    });
                    this.setState({ total: tot });
                }
                else{
                    this.setState({ carritoLs:[] });
                    this.setState({ total: tot });
                }
            })
            .catch(e => console.log(e));
    };
    
    componentDidMount() {
        this.goToken();
        this.getCostoTarjeta();
        var tokenCompra = '';
        var x=window.location + "";
        var ruta = x.split('/');
        
        if(ruta[3] === "#compraWeb"){
            tokenCompra = ruta[4];
            this.setState({tokenCompra:tokenCompra});
            this.cambiaSeccion('compraWeb');
        }else if(ruta[3] === '#fiestas'){
            this.cambiaSeccion('fiestas', '');
        }else if(ruta[3] === '#venta'){
            this.cambiaSeccion('accesos', '');
        }
        
        ReactPixel.init('494368702952994',{}, {autoConfig: true, debug: false});
        ReactPixel.pageView();
    };

    render() {

    const responsive = {
            desktopl: {
                breakpoint: { max: 3000, min: 1440 },
                items: 1,
                slidesToSlide: 1, // optional, default to 1.
            },
            desktops: {
                breakpoint: { max: 1439, min: 1024 },
                items: 1,
                slidesToSlide: 1, // optional, default to 1.
            },
            tablet: {
                breakpoint: { max: 768, min: 464 },
                items: 1,
                slidesToSlide: 4, // optional, default to 1.
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1, // optional, default to 1.
            },
    };

    const responsive_sucursales = {
        desktopl: {
            breakpoint: { max: 3000, min: 1440 },
            items: 3,
            slidesToSlide: 1, // optional, default to 1.
        },
        desktops: {
            breakpoint: { max: 1439, min: 1024 },
            items: 3,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: 1,
            slidesToSlide: 4, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };

    const responsive_atracciones = {
        desktopl: {
            breakpoint: { max: 3000, min: 1440 },
            items: 3,
            slidesToSlide: 1, // optional, default to 1.
        },
        desktops: {
            breakpoint: { max: 1439, min: 1024 },
            items: 3,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: 3,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };

    const responsive_combos = {
        desktopl: {
            breakpoint: { max: 3000, min: 1440 },
            items: 4,
            slidesToSlide: 1, // optional, default to 1.
        },
        desktops: {
            breakpoint: { max: 1439, min: 1024 },
            items: 4,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };

        return (
            <div>
                {/* ------------------------------- NAV SECCION -------------------------------------*/}
                 <nav className="navbar fixed-top navbar-expand-xl bg-light shadow-lg" style={{height:'80px'}}>
                    <div className="container-fluid">
                         <a href="">
                          <img style={{ height:"70px" }} src={this.state.servurlassets + "logo.png"} alt="logo" />
                         </a>
                        <button className="navbar-toggler text-light" onClick={this.handleClick.bind(this)} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
                         <img style={{ width: 40}} src={this.state.servurlassets + "menus.png"} />
                        </button>
                        <div className="collapse navbar-collapse">
                            <ul className="navbar-nav m-auto">
                                <li className="nav-item" key="uniqueId1">
                                    <a className="nav-link" href="#div_compras" onClick={this.cambiaSeccion.bind(this, "main", "", "")}>
                                      <h5 className="text-danger fuenteMenu">Precios y compra en línea</h5>
                                    </a>
                                </li>
                                <li className="nav-item" key="uniqueId2">
                                    <a className="nav-link" href="#div_promociones" onClick={this.cambiaSeccion.bind(this, "main", "", "")}>
                                      <h5 className="text-danger fuenteMenu">Promociones</h5>
                                    </a>
                                </li>
                                <li className="nav-item" key="uniqueId3">
                                    <a className="nav-link" href="#div_atracciones" onClick={this.cambiaSeccion.bind(this, "main", "", "")}>
                                      <h5 className="text-danger fuenteMenu">Atracciones</h5>
                                    </a>
                                </li>
                                <li className="nav-item" key="uniqueId4">
                                    <a className="nav-link" href="#" onClick={this.cambiaSeccion.bind(this, "menu", "", "")}>
                                      <h5 className="text-danger fuenteMenu">Menú</h5>
                                    </a>
                                </li>
                                <li className="nav-item" key="uniqueId5">
                                    <a className="nav-link" href="#" onClick={this.cambiaSeccion.bind(this, "fiestas", "", "")}>
                                      <h5 className="text-danger fuenteMenu">Fiestas</h5>
                                    </a>
                                </li>
                                <li className="nav-item mb-0" key="uniqueId6">
                                    <a className="nav-link" href="#div_sucursales" onClick={this.cambiaSeccion.bind(this, "main", "", "")}>
                                      <h5 className="text-danger fuenteMenu">Sucursales</h5>
                                    </a>
                                </li>
                                    <a href="#" className="cart" onClick={this.showModal.bind()}>
                                      <img style={{ width:'40px' }} src={this.state.servurlassets + "carrito.png"} />
                                      <span id="cart_menu_num" data-action="cart-can" className="badge rounded-circle">{this.state.carritoLs.length}</span>
                                    </a>                                   
                            </ul>
                        </div>
                    </div>
                 </nav>

                {/* ------------------------------- NAV SECCION MOVIL -------------------------------------*/}
                 <OffCanvas
                    width={300}
                    transitionDuration={300}
                    effect={"parallax"}
                    isMenuOpened={this.state.isMenuOpened}
                    position={"right"}>
                    <OffCanvasBody>
                    </OffCanvasBody>
                    <OffCanvasMenu className="menu_movil">
                        <ul className="list-group list-group-flush">
                            <br/><br/><br/><br/>
                            <li key="uniqueId8" className="list-group-item bg-light border-5">
                            <button onClick={this.handleClick.bind(this)} type="button" className="btn-close" aria-label="Close">
                            </button>
                            </li>
                            <li key="uniqueId9"  className="list-group-item bg-dark" onClick={this.cambiaSeccion.bind(this, "main", "", "")}><a href="#div_compras" onClick={this.handleClick.bind(this)} className="fuente text-light">Precios y compra en línea</a></li>
                            <li key="uniqueId10" className="list-group-item bg-dark" onClick={this.cambiaSeccion.bind(this, "main", "", "")}><a href="#div_promociones" onClick={this.handleClick.bind(this)} className="fuente text-light">Promociones</a></li>
                            <li key="uniqueId11" className="list-group-item bg-dark" onClick={this.cambiaSeccion.bind(this, "main", "", "")}><a href="#div_atracciones" onClick={this.handleClick.bind(this)} className="fuente text-light">Atracciones</a></li>
                            <li key="uniqueId12" className="list-group-item bg-dark" onClick={this.cambiaSeccion.bind(this, "menu", "", "")}><a onClick={this.handleClick.bind(this)} className="fuente text-light">Menú</a></li>
                            <li key="uniqueId13" className="list-group-item bg-dark" onClick={this.cambiaSeccion.bind(this, "fiestas", "", "")}><a onClick={this.handleClick.bind(this)} className="fuente text-light">Fiestas</a></li>
                            <li key="uniqueId14" className="list-group-item bg-dark" onClick={this.cambiaSeccion.bind(this, "main", "", "")}><a href="#div_sucursales" onClick={this.handleClick.bind(this)} className="fuente text-light">Sucursales</a></li>
                            <li key="uniqueId15" className="list-group-item bg-dark">
                              <a href="#" className="cart" onClick={this.showModal.bind()}>
                               <img style={{ width:'40px' }} src={this.state.servurlassets + "carrito.png"}  />
                               <span id="cart_menu_num" data-action="cart-can" className="badge rounded-circle">{this.state.carritoLs.length}</span>
                              </a>
                            </li>
                        </ul>
                    </OffCanvasMenu>
                 </OffCanvas>

                {/* ------------------------------- SECCIONES -------------------------------------*/}
                 <div>
                    <br/><br/><br/>
                    {
                    this.state.cursecc === "main" ? 
                        <Secc_inicio responsive = {responsive} servurlbase = {this.state.servurlbase} servurlassets = {this.state.servurlassets}  responsive_sucursales = {responsive_sucursales} responsive_atracciones = {responsive_atracciones} cambiaSeccion = {this.cambiaSeccion}/>
                    :this.state.cursecc === "fiestas" ? 
                        <Secc_fiestas servurlassets = {this.state.servurlassets} servurlbase = {this.state.servurlbase} />
                    :this.state.cursecc === "accesos" ? 
                        <Secc_accesos cambiaSeccion = {this.cambiaSeccion} servurlassets = {this.state.servurlassets} servurlbase = {this.state.servurlbase} token = {this.state.token} costotarjeta = {this.state.costotarjeta} getCarrito = {this.getCarrito} showModalCargando = {this.showModalCargando} cierraCargando = {this.cierraCargando}/>
                    :this.state.cursecc === "combos" ? 
                        <Secc_combos cambiaSeccion = {this.cambiaSeccion} responsive_combos = {responsive_combos} servurlassets = {this.state.servurlassets} servurlbase = {this.state.servurlbase} token = {this.state.token} costotarjeta = {this.state.costotarjeta} getCarrito = {this.getCarrito} showModalCargando = {this.showModalCargando} cierraCargando = {this.cierraCargando}/>
                    :this.state.cursecc === "promociones" ? 
                        <Secc_promociones cambiaSeccion = {this.cambiaSeccion} codigo={this.state.codigo} servurlassets = {this.state.servurlassets} servurlbase = {this.state.servurlbase} token = {this.state.token} getCarrito = {this.getCarrito} showModalCargando = {this.showModalCargando} cierraCargando = {this.cierraCargando}/>
                    :this.state.cursecc === "bolsa" ? 
                        <Secc_bolsa servurlassets = {this.state.servurlassets} servurlbase = {this.state.servurlbase} token = {this.state.token} showModalCargando = {this.showModalCargando} cierraCargando = {this.cierraCargando}/>
                    :this.state.cursecc === "aviso" ? 
                        <Secc_aviso servurlassets = {this.state.servurlassets} servurlbase = {this.state.servurlbase} />
                    :this.state.cursecc === "reembolsos" ? 
                        <Secc_reembolsos servurlassets = {this.state.servurlassets} servurlbase = {this.state.servurlbase} />
                    :this.state.cursecc === "tyc" ? 
                        <Secc_tyc servurlassets = {this.state.servurlassets} servurlbase = {this.state.servurlbase} />
                    :this.state.cursecc === "menu" ? 
                        <Secc_menu servurlassets = {this.state.servurlassets} servurlbase = {this.state.servurlbase} cambiaSeccion = {this.cambiaSeccion} />
                    :this.state.cursecc === "compraWeb" ? 
                        <Secc_compraWeb remplazarToken = {this.remplazarToken} servurlbase = {this.state.servurlbase} tokenCompra = {this.state.tokenCompra}/> 
                     : 
                     ""
                    }
                 </div>

                {/* ------------------------------- BAR SOCIAL -------------------------------------*/}
                 <div className="sidebarSocial desapareceimagen">
                    <div className="social-icons-container">
                        <a href="https://www.facebook.com/UpaByRecorcholis/" className="a-link" target="_blank">
                         <img style={{ width: 40, height: 40 }} src={this.state.servurlassets + 'facebook.png'} alt="Alternate Text" />
                        </a>
                        <a href="https://www.instagram.com/upabyrecorcholis/" className="a-link" target="_blank">
                         <img style={{ width: 40, height: 40 }} src={this.state.servurlassets + 'instagram.png'} alt="Alternate Text" />
                        </a>
                        <a href="" className="a-link" target="_blank">
                         <img style={{ width: 40, height: 40 }} src={this.state.servurlassets + 'youtube.png'} alt="Alternate Text" />
                        </a>
                        <a href="https://twitter.com/uparecorcholis" className="a-link" target="_blank">
                         <img style={{ width: 40, height: 40 }} src={this.state.servurlassets + 'twitter.png'} alt="Alternate Text" />
                        </a>
                    </div>
                 </div>
                
                 {/* ------------------------------- FOOTER SECCION -------------------------------------*/}

                <footer>
                        <div className="container-fluid bg-warning">
                            <div className="row">
                                <div className="col-sm-3 col-12 text-center" style={{ cursor: "pointer" }}>
                                <a className="text-decoration-underline fw-bold text-dark" onClick={this.cambiaSeccion.bind(this, "tyc", "", "")}>Términos y condiciones</a>
                                </div>
                                <div className="col-sm-3 col-12 text-center" style={{ cursor: "pointer" }}>
                                <a className="text-decoration-underline fw-bold text-dark" onClick={this.cambiaSeccion.bind(this, "aviso", "", "")}>Aviso de privacidad</a>
                                </div>
                                <div className="col-sm-3 col-12 text-center" style={{ cursor: "pointer" }}>
                                <a className="text-decoration-underline fw-bold text-dark" onClick={this.cambiaSeccion.bind(this, "bolsa", "", "")}>Bolsa de trabajo</a>
                                </div>
                                <div className="col-sm-3 col-12 text-center"style={{ cursor: "pointer" }}>
                                <a className="text-decoration-underline fw-bold text-dark" onClick={this.cambiaSeccion.bind(this, "reembolsos", "", "")}>Reembolsos y devoluciones</a>
                                </div>

                                {
                                    this.state.cursecc === "main" ? 
                                    <div>
                                        <div className="col-sm-12 col-12 text-center">
                                        <br/><br/>
                                        </div>
                                        <div className="col-sm-12 col-12 text-center">
                                        <h5 className="fw-bold text-dark">Pago seguro con:</h5>
                                        </div>
                                        <div className="col-sm-12 col-12 text-center">
                                            <div className="row">
                                                <div className="col-sm-3 col-12"></div>
                                                <div className="col-sm-3 col-12 text-center">
                                                    <br/>
                                                <a><img width={'90%'} height={'100px'} src={this.state.servurlassets + "aplazo.png"} alt="clinet"></img></a>
                                                </div>
                                                <div className="col-sm-3 col-12 text-center">
                                                <br/>
                                                <a><img width={'40%'} height={'100px'}src={this.state.servurlassets + "mercado.png"} alt="clinet"></img></a>
                                                </div>
                                                <div className="col-sm-3 col-12"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12 col-12">
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                        </div>
                                    </div>   
                                    : 
                                    ""
                                    }
                            </div>
                        </div>
                </footer>

                 {
                  this.state.modal === true?
                    <Modal_carrito cierra = {this.cierra} servurlassets = {this.state.servurlassets} servurlbase = {this.state.servurlbase} token = {this.state.token}  remplazarToken = {this.remplazarToken} carritoLs = {this.state.carritoLs} total = {this.state.total} getCarrito = {this.getCarrito} showModalCargando = {this.showModalCargando} cierraCargando = {this.cierraCargando} cambiaSeccion = {this.cambiaSeccion}/>
                  :''
                 }
                 {
                  this.state.modal_cargando === true?
                    <Modal_cargando  servurlassets = {this.state.servurlassets}/>
                  :''
                 }
            </div>
        );
    }
}
export default MainDesktop;
