import React, { Component } from "react";
import Modal_bolsa from "./modal_bolsa";

class Secc_bolsa extends Component {
    constructor() {
        super();

        this.state = {
            modal:false,
        };
    }

    showModal=()=>{
        this.setState({modal:true});
    }

    cierra= ()=>{
        this.setState({modal:false});
    }

    

    render() {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row row_"  style={{ backgroundColor:'#90e6ff' }} >
                        <div className="col-sm-12 text-center fuente">
                            <br/>
                            <h1>Te estamos buscando</h1>
                        </div>
                        <div className="col-sm-9 fiestas text-center" style={{textAlign:'justify'}}>
                            <br/><br/>
                            <h2 className="fuente text-danger">
                             Queremos que te unas al equipo más divertido.
                            </h2>
                            <h5 className="fw-bold text-black">
                             No solo te divertirás trabajando, aprenderás y adquirirás nuevas habilidades, conocerás gente nueva, podrás crecer profesionalmente y lo más importante es que ¡Harás feliz a la gente!
                            </h5>
                            <h3 className="fuente text-danger">
                             ¿Qué necesitas para unirte al equipo ¡UPA!?
                            </h3>
                            <h5 className="fw-bold text-black">
                            • Tener al menos 18 años.<br/>
                            • Ser entusiasta, alegre, extrovertido, contar con una gran sonrisa, tener pasión por la diversión y sobre todo que te guste atender a los invitados.
                            </h5>
                            <h1 className="fw-bold text-center fuente morado">
                             #SoyUPA
                            </h1>
                        </div>
                        <div className="col-sm-3 text-center">
                          <img className="img-fluid desapareceimagen jump_nino" src={this.props.servurlassets + "nino.png"}  />
                        </div>
                        <div className="col-sm-12 text-center">
                         <a href='#' className="btn btn-danger text-light fw-bold" onClick={this.showModal.bind()}>Solicitud</a>
                        </div>
                        <div className="col-sm-12">
                         <br/><br/><br/><br/><br/><br/>
                        </div> 
                    </div>
                    {
                        this.state.modal === true?
                            <Modal_bolsa cierra={this.cierra} servurlbase = {this.props.servurlbase} showModalCargando = {this.props.showModalCargando} cierraCargando = {this.props.cierraCargando}/>
                        :''
                    }
                </div>
            </div>
        );
    }
}
export default Secc_bolsa;
