import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody,Row,Input,ModalC} from 'reactstrap';
import './desktop.css';

class Modal_cargando extends Component{

    constructor() {
        super();

        this.state ={
           
        }

    }

    render(){
        
        return(
            <div >
                <Modal className='modal-content modal-dialog' isOpen={true}>
                    <ModalBody className='text-center'>
                      <img className='loaderimg' width={250} src={this.props.servurlassets + "job.png"} />
                      <span class="loader text-secondary">Cargando...</span>
                    </ModalBody>
                </Modal>    
            </div>
        );
    }    
}

export default Modal_cargando