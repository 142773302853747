import React, { Component } from 'react';
import './desktop.css';
import Collapse from 'react-bootstrap/Collapse';
import Modal_tyc from './modal_tyc';

class Secc_tyc extends Component{
    constructor() {
        super();
        this.state ={
            secciones:[],
            promociones:[],
            tyc:false,
            modal_tyc:false,
            tyc_:''
        };
    }

    getSecciones = () => {
        let data = {
            "secciones" : true
        }

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };

        fetch(this.props.servurlbase + '/getTerminosCondiciones.php', requestInfo)
        .then(response => response.json())
        .then(secciones => {
            if (secciones) {
                this.setState({ secciones });
            } 
        }).catch(e => console.log(e)) 
    }

    getPromociones = (title) => {
        let data = {
            title : "" + title
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.props.servurlbase + '/getTerminosCondiciones.php', requestInfo)
        .then(response => response.json())
        .then(promociones => {
            if (promociones) {
                this.setState({ promociones });
            } 
        }).catch(e => console.log(e))  
    }

    showModal=(valor) => {
        this.setState({modal_tyc:true});
        this.setState({tyc_:valor});
    };

    cierra = () => {
        this.setState({modal_tyc:false});
    };

    componentDidMount() {
     this.getSecciones();
     this.getPromociones("Recargas en línea");
    };
     
    render(){
        return(
            <div>
                <div className="container-fluid">
                    <div className="row row_" style={{ backgroundColor:'#f99037',padding:'2em' }} >
                        <div className="col-sm-12 col-12 text-center">
                          <br/>
                          <h1 className='fuente text-light'>Términos y condiciones</h1>
                        </div>
                        <div className="col-sm-12 col-12">
                            <div className='row'>
                                {this.state.secciones.length > 0 ? this.state.secciones.map((objeto, index) => (
                                    <div key={index} className="col-sm-3 col-12 text-center" style={{ cursor:'pointer' }}>
                                        <h4 className='fw-bold text-light' style={{cursor:'pointer'}} onClick={this.getPromociones.bind(this,objeto.title)}> - {objeto.title} - </h4>
                                    </div>
                                )):<div></div>}
                            </div>
                        </div>
                        <div className='col-sm-12 col-12'>
                            <Collapse in={this.state.promociones.length > 0 ? true : false}>
                                <div className="card card-body">
                                    <ul className="list-group">
                                        {this.state.promociones.length > 0 ? this.state.promociones.map((objeto, index) => (
                                            <li className="list-group-item">
                                                <h5 className='fuente text-danger' style={{cursor:'pointer'}} onClick={this.showModal.bind(this,objeto.tyc)} >{objeto.promocion}</h5>
                                            </li>))
                                        : <li></li>}
                                    </ul>
                                </div>
                            </Collapse>
                        </div>
                        <div className="col-sm-12 col-12"><br/><br/></div>
                    </div>
                </div>
                {this.state.modal_tyc === true?
                    <Modal_tyc cierra = {this.cierra} servurlassets = {this.props.servurlassets} servurlbase = {this.props.servurlbase} tyc_ = {this.state.tyc_}/>
                :''}
            </div>
        )
    }

}
export default Secc_tyc