import React, { Component } from 'react';
import {Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,Row} from 'reactstrap';
import './desktop.css';

class Modal_tyc extends Component{

    constructor() {
        super();
        this.state ={
        }

    }

    
    render(){
        
        return(
            <div>
                <Modal className='modal-lg' isOpen={true} toggle={this.props.cierra}>
                    <ModalHeader  className='text-white bg-danger' toggle={this.props.cierra}>
                        Términos y condiciones
                    </ModalHeader>
                    <ModalBody className='bg-light'>
                        <Row>
                         <Col className="col-sm-12">   
                           <div style={{textAlign:'justify'}}>
                            <pre >{this.props.tyc_}</pre>
                           </div>
                         </Col>
                        </Row>
                    </ModalBody>
                </Modal>    
            </div>
        );
    }    
}

export default Modal_tyc