import React, { Component } from "react";

class Secc_menu extends Component {
    constructor() {
        super();

        this.state = {
        };
    }

    render() {
        return (
            <div>
              <div className='container-fluid bg-danger'>
                <div className="row row_">
                    <div className="col-sm-5 col-12">
                        <br/>
                        <div className="row">
                            <div className="col-sm-12 col-12">
                             <h1 className="fuente text-light">Snacks</h1>
                             <h4 className="text-light">Lo que se te antoje</h4>
                             <h5 className="text-light">Encuentra el menú en nuestro código QR en la zona de Snacks 
                             de nuestras sucursales UPA O presiona en esta imagen para verlo.</h5>
                            </div>
                            <div className="col-sm-12 col-12 text-center">
                                <a href='https://upa.com.mx/menu/index.html' target={'_blank'}><img className="contenedor" style={{ width: '70%' ,cursor:'pointer'}} src={this.props.servurlassets + 'QR_menu.png'} alt="Alternate Text" /></a>
                            </div>
                            <div className="col-sm-12 col-12">
                             <br/>
                             <h6 className="text-light">Productos sujetos a disponibilidad. Consulta las sucursales de UPA <a className="fw-bold" href="#div_sucursales" onClick={this.props.cambiaSeccion.bind(this, "main", "", "")}>AQUÍ</a></h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-7 col-12">
                        <br/>
                        <div className="row">
                            <div className="col-sm-4 border-0">
                             <img className="desapareceimagen borde_div_blanco contenedor" width={'100%'} src={this.props.servurlassets + 'tato_mesero.png'} alt="Alternate Text" />
                            </div>
                            <div className="col-sm-4">
                             <img className="desapareceimagen borde_div_blanco contenedor" width={'100%'} src={this.props.servurlassets + 'hamburguesa.png'} alt="Alternate Text" />
                            </div>
                            <div className="col-sm-4">
                             <img className="desapareceimagen borde_div_blanco contenedor" width={'100%'} src={this.props.servurlassets + 'papas.png'} alt="Alternate Text" />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-12">
                     <br/><br/><br/>
                    </div>
                </div>
              </div>
            </div>
        );
    }
}
export default Secc_menu;
