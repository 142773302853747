import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody,Row} from 'reactstrap';
import './desktop.css';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
  

class Modal_ubicacion extends Component{

    constructor(props) {
        super(props);


    }

    componentDidMount() {

    }

    render(){

        const Marker = props => {
            return <div><img alt='ok' style={{height:"30px",width:"15px"}} src='https://toppng.com/public/uploads/thumbnail/how-to-set-use-location-marker-clipart-11563643968ftrg9ioing.png' /></div>
        }

        const center = {
            lat: parseFloat(this.props.objetos_ubicacion.lat),
            lng: parseFloat(this.props.objetos_ubicacion.lng)
          };

          const containerStyle = {
            width: '100%',
            height: '450px'
          };

     
        return(
            <div>
                <Modal className='modal-dialog' isOpen={true}>
                    <ModalHeader className='text-dark bg-light' toggle={this.props.cierra}>
                      Ubicación
                    </ModalHeader>
                        <ModalBody className='bg-danger'>
                            <Row>
                            <div className='text-center'>
                        <LoadScript googleMapsApiKey="AIzaSyC-6-ekQ4Y7yRLTceu2jNGBAE37g0n-FW0">
                                <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={15}
                                >
                                 <Marker lat={this.props.objetos_ubicacion.lat} lng={this.props.objetos_ubicacion.lng}/>
                                </GoogleMap>
                       </LoadScript> 
                       
                            </div>
                            </Row>
                        </ModalBody>
                        <footer className='bg-dark'>
                            <br/>
                        </footer>
                </Modal>    
            </div>
        );
    }    
}

export default Modal_ubicacion