import React, { Component } from 'react';
import MainDesktop from './comp_desktop/main';

class Main extends Component {
    
    constructor() {
        super();

        this.state ={
        };
    }

    render() {

        return (
            <div style={{margin:0}}>
                {
                 <MainDesktop />
                }
            </div>
        )
    }

}
export default Main