import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody,Row} from 'reactstrap';
import './desktop.css';
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';

class Modal_combos extends Component{

    constructor() {
        super();

        this.state ={
            div_recorcard:"hidden",
            response_card:false
        }

    }

    checked = () => {
        document.getElementById("recorcard").value = "";
        if(document.getElementById("nueva").checked){
            this.setState({div_recorcard:'hidden'});
        }
        else if(document.getElementById("preexistente").checked){
            this.setState({div_recorcard:'visible'});
        }
        else if(document.getElementById("cupon").checked){
            this.setState({div_recorcard:'hidden'});
        }
    };

    agregaCarrito = (combo) => {
        this.props.showModalCargando();
        var recorcard = document.getElementById("recorcard").value
        this.valida_tarjeta(recorcard);
        var tipo = "";

        if( document.getElementById("nueva").checked ){
            tipo = "nueva";
        }
        else if( document.getElementById("preexistente").checked ){
            tipo = "preexistente";

            if(recorcard == ""){
                Swal.fire('Error','Llene todos los campos para continuar','warning');
                this.props.cierraCargando();
                return false;
            }
        }
        else if( document.getElementById("cupon").checked ){
            tipo = "cupon";
        }

        if(!tipo){
            Swal.fire('Error','Llene todos los campos para continuar','warning');
            this.props.cierraCargando();
            return false;
        }

        let data = {
            codigo_combo: combo.codigo,
            token: this.props.token,
            recorcard: recorcard,
            tipo: tipo,
            costotarjeta: this.props.costotarjeta,
            monto:combo.monto,
            descripcion:combo.title,
        };

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };

        setTimeout(() => {//166-345526-2 TARJETA INCORRECTA

            if(this.state.response_card === false && tipo == "preexistente"){

                Swal.fire('Error','Tarjeta Invalida','warning');
                document.getElementById("recorcard").value = "";
                this.props.cierraCargando();
                return false;
            }
            else{//TARJETA CORRECTA

                fetch(this.props.servurlbase + '/addCarrito.php', requestInfo)
                .then(response => response.json())
                .then(respuesta => {
                    if (respuesta) {
                        this.props.getCarrito(this.props.token);
                        Swal.fire('Artículo agregado a carrito','','success');
                        this.props.cierraCargando();
                    } else {
                        Swal.fire('Error','Upss, algo ocurrió, intente mas tarde','warning');
                        this.props.cierraCargando();
                    }
                })
                .catch(e => console.log(e));
            }
        }, 1000);
    };

    valida_tarjeta = (CGUID)=>{
        let data ={
            CGUID
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };

        fetch(this.props.servurlbase + '/GetCard.php', requestInfo)
        .then(response => response.json())
        .then(detalles => {  

            if(detalles.Code == 0){     
             this.setState({response_card:true});
            }
            else{
             this.setState({response_card:false});  
            }

        })

        .catch(e=>console.log(e));
    };

    defaultCh = () => {

        setTimeout(() => {
            document.getElementById("cupon").checked = true;   
        }, 500);

    };

    componentDidMount() {
        this.defaultCh();
    };

    render(){
        
        return(
            <div>
                <Modal className='modal-lg' isOpen={true}>
                    <ModalHeader className='text-dark bg-light' toggle={this.props.cierra}>
                     {this.props.combo.title}
                    </ModalHeader>
                        <ModalBody className='bg-warning'>
                          <Row> 
                            <div className='col-sm-5 col-12 text-center'>
                             <img className='img-fluid shadow-lg contenedor' src={this.props.servurlassets + this.props.combo.nombre + this.props.combo.extension}/>
                            </div>
                            <div className='col-sm-7 col-12'>
                            <div className="row">
                                    <div className="col-sm-12 col-12">
                                        <br/>
                                    <h4 className="text-dark fw-bold text-center fuente">Quieres aplicar tu combo en:</h4>
                                    </div> 
                                    <div className="col-sm-6 col-12"></div>
                                    <div className="col-sm-12 col-12 form-check" style={{paddingLeft:'2em'}}>
                                        <br/>
                                        <input className="form-check-input" type="radio" onClick={this.checked.bind()}  name="radio" value={'preexistente'} id="preexistente"/>
                                        <h5 className="text-dark">En tarjeta preexistente</h5>
                                    </div>
                                    <div className="col-sm-12 col-12 form-check" style={{paddingLeft:'2em'}}>
                                        <input className="form-check-input" type="radio" onClick={this.checked.bind()} name="radio" value={'nueva'} id="nueva"/>
                                        <h5 className="text-dark">En tarjeta nueva ( + $40.00)</h5>
                                    </div>
                                    <div className="col-sm-12 col-12 form-check" style={{paddingLeft:'2em'}}>
                                        <input className="form-check-input" type="radio" onClick={this.checked.bind()} name="radio" value={'cupon'} id="cupon" />
                                        <h5 className="text-dark">En cupón digital</h5>
                                    </div>
                                    <div className="col-sm-6 col-12" id="div_recorcard" style={{ visibility:this.state.div_recorcard}}>
                                        <InputMask mask="999-999999-9" className="form-control" alwaysShowMask={true} maskChar="">
                                            {({ inputRef }) => (
                                            <input
                                                type="text"
                                                id="recorcard"
                                                className="form-control"
                                                maxLength={12}
                                                ref={inputRef} // Pasar la referencia del input al InputMask
                                            />
                                            )}
                                        </InputMask>
                                    </div>
                                    <div className="col-sm-12 col-12 text-center">
                                     <br/>
                                     <button type="button" id="btn_add" className="btn btn-light fw-bold text-danger" onClick={this.agregaCarrito.bind(this,this.props.combo)}>Agrega al carrito</button>
                                    </div>
                                    <div className='col-sm-12 col-12 text-center'>
                                        <br/>
                                        <a href="#" class="text-decoration-none fw-bold" onClick={this.props.cambiaSeccion.bind(this, "tyc" , "", "")}>Consulta términos y condiciones</a>
                                    </div>
                            </div>

                            </div>
                          </Row>
                        </ModalBody>
                        <footer className='bg-dark'>
                            <br/>
                        </footer>
                </Modal>    
            </div>
        );
    }    
}

export default Modal_combos