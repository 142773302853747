import React, { Component } from 'react';
import './desktop.css';

class Secc_reembolsos extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[]
        };
    }
     
    render(){
        return(
            <div>
                <div className="container-fluid">
                    <div className="row row_" style={{ backgroundColor:'#f99037' }} >
                        <div className="col-sm-9" style={{ textAlign:'justify' }}>
                            <br/>
                            <h3 className='fuente text-light'>Politica de Reembolsos y devoluciones </h3> 
                            <p className='text-light fw-bold'>
                            La presente política de reembolsos y devoluciones regula la relación entre Usted 
                            (en lo sucesivo el “Usuario”) y UPA by ¡Recórcholis! en relación con la compraventa 
                            de servicios por medio del sitio web recorcholis.com.mx. Al hacer uso de este servicio 
                            usted entiende, está conforme y acepta expresa e irrevocablemente los mismos, así como 
                            las modificaciones o adiciones que sean aplicables y se encuentren vigentes al momento 
                            de utilizar los servicios que el sitio web de upa.com.mx ofrece.
                            </p>
                            <p className='text-light fw-bold'> 
                            El Usuario, expresamente reconoce y acepta que la solicitud de compra de las recargas, 
                            paquetes, promociones o tarjetas de regalo en todas sus opciones y modalidades de compra 
                            que realiza a través del sitio web upa.com.mx, es una oferta vinculante y que una vez 
                            recibida la autorización del banco emisor de la tarjeta de crédito o débito correspondiente
                            o del banco aceptante, la operación de compra será definitiva y no estará sujeta a cambios
                            o cancelaciones. 
                            </p>
                            <h3 className='fuente text-light'>¿Qué debo hacer en caso de solicitar un reembolso o devolución?</h3> 
                            <p className='text-light fw-bold'>
                            En caso de solicitar una reembolso o devolución de la compra, El Usuario deberá solicitarlo
                            al banco emisor de la tarjeta de crédito o débito que se haya utilizado al realizar la compra
                            y se iniciará un proceso de “revisión de pago”, en el cual se tomarán en consideración las
                            siguientes condiciones para realizar el reembolso o devolución: 
                            </p>
                            <ul>
                              <li className="text-light"><h6 className="fw-bold">No haber redimido el/los cupones electrónicos recibidos al realizar la compra.</h6></li>
                              <li className="text-light"><h6 className="fw-bold">Realizar el reclamo o solicitud de reembolso o devolución en un periodo máximo de 7 días.</h6></li>
                              <li className="text-light"><h6 className="fw-bold">El periodo para percibir el reembolso o devolución, en caso de proceder, será de 5 a 10 días hábiles.</h6></li>
                            </ul>
                        </div>
                        <div className="col-sm-3 col-12">
                            <img className="img-fluid desapareceimagen jump_nina" src={this.props.servurlassets + "niña.png"}  />
                        </div>
                        <div className="col-sm-12 col-12"><br/><br/></div>
                    </div>
                </div>
            </div>
        )
    }

}
export default Secc_reembolsos