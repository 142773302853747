import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody,Row,Input} from 'reactstrap';
import Collapse from 'react-bootstrap/Collapse';
import './desktop.css';

class Modal_atracciones extends Component{

    constructor() {
        super();

        this.state ={
          Collapse:false
        }

    }

    render(){
        
        return(
            <div>
                <Modal className='modal-dialog' isOpen={true}>
                    <ModalHeader className='bg-light'  toggle={this.props.cierraAtracciones}>
                    </ModalHeader>
                        <ModalBody className='bg-danger'>
                          <Row> 
                            <div className='col-sm-5 col-12 text-center'>
                              <img className="rounded-2" src={this.props.servurlassets + this.props.objetos_atraccion.img} style={{ width:'100%', maxHeight:'450px' }} />
                            </div>
                            <div className='col-sm-7 col-12' style={{ textAlign:'justify' }}>
                                <br/>
                                <h4 className='text-light fuente'>{this.props.objetos_atraccion.title}</h4>
                                <p className='text-light fw-bold'>{this.props.objetos_atraccion.descripcion}</p>
                            </div>
                          </Row>
                        </ModalBody>
                </Modal>    
            </div>
        );
    }    
}

export default Modal_atracciones