import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody,Row,Input} from 'reactstrap';
import './desktop.css';
import validator from 'validator'
import Swal from 'sweetalert2';

class Modal_fiestas extends Component{

    constructor() {
        super();

        this.state ={
           
        }

    }

    enviar = () => {
        document.getElementById("btn_enviar").disabled = true;
    
        var nombre = document.getElementById("nombre").value;
        var correo = document.getElementById("correo").value;
        var telefono = document.getElementById("telefono").value;
        var invitados = document.getElementById("invitados").value;

        if( !nombre || !correo || !telefono || !invitados ){
            Swal.fire("Error", "Llene todos los campos", "warning");
            document.getElementById("btn_enviar").disabled = false;
            return false;
        }

        if( invitados < 2 ){
            Swal.fire("Error", "Valor de invitados inválido", "warning");
            document.getElementById("btn_enviar").disabled = false;
            return false;
        }

        if (!validator.isEmail(correo)) {
            Swal.fire("Error", "Correo inválido", "warning");
            document.getElementById("btn_enviar").disabled = false;
            return false;
        } 
              
        let data = {
            cuerpo: "Asunto:<strong>Cotización de Fiestas UPA</strong><br/><br/>Nombre del interesado: " + nombre + " <br/>Correo: " + correo + " <br/>Teléfono: " + telefono + " <br/>Cantidad de invitados: " + invitados,
            email: 'aosorio@grupodiniz.com.mx',
            asunto: "Contacto de invitado Portal UPA"
        }

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };

        fetch(this.props.servurlbase + '/sendCorreoContacto.php', requestInfo)
        .then((response) => {
            if(response){
                Swal.fire(
                    'Enviado',
                    'Se ha enviado su información',
                    'success'
                )
                document.getElementById("btn_enviar").disabled = false;
                document.getElementById("nombre").value = '';
                document.getElementById("correo").value = '';
                document.getElementById("telefono").value = '';
                document.getElementById("invitados").value = '';
            }
            else{
                Swal.fire("Error", "Error", "warning");
                document.getElementById("btn_enviar").disabled = false;
            }
        })
        .catch(e => console.log(e));
     
    }


    render(){
        
        return(
            <div>
                <Modal className='modal-dialog' isOpen={true}>
                    <ModalHeader className='text-danger fw-bold bg-light' toggle={this.props.cierra}>
                      Reserva tu fiesta
                    </ModalHeader>
                        <ModalBody className='bg-danger'>
                            <Row>
                            <div className="col-sm-12 col-12 text-center">
                             <h3 className='text-light fw-bold'>¡Reserva tu fiesta con nosotros!</h3>
                             <a href='https://wa.me/+525526979865?text=%C2%A1Hola!%20Solicito%20informaci%C3%B3n%20de%20eventos%20empresariales' target={'_blank'} className="text-light fw-bold">Si tienes dudas da click aquí <img width={20} src={this.props.servurlassets + "whatsapp.png"} /></a>
                             
                            </div>
                            <div className="col-sm-12 col-12 text-center">
                             <p className='text-light fw-bold'>Nombre completo</p>
                             <input type='text' id='nombre' className='form-control' style={{textAlign:'center'}}/>
                            </div>
                            <div className="col-sm-12 col-12 text-center">
                             <p className='text-light fw-bold'>Teléfono</p>
                             <input type='text' id='telefono' className='form-control' style={{textAlign:'center'}}/>
                            </div>
                            <div className="col-sm-12 col-12 text-center">
                             <p className='text-light fw-bold'>Correo</p>
                             <input type='text' id='correo' className='form-control' style={{textAlign:'center'}}/>
                            </div>
                            <div className="col-sm-12 col-12 text-center">
                             <p className='text-light fw-bold'>Cantidad de invitados</p>
                             <input type='number' id='invitados' className='form-control' style={{textAlign:'center'}}/>
                            </div>
                            <div className="col-sm-12 col-12 text-center">
                             <br/>
                             <button type='button' className='btn btn-light text-danger fw-bold' id='btn_enviar' onClick={this.enviar.bind(this)}>ENVIAR</button>
                            </div>
                            </Row>
                        </ModalBody>
                        <footer className='bg-dark'>
                            <br/>
                        </footer>
                </Modal>    
            </div>
        );
    }    
}

export default Modal_fiestas