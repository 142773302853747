import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody,Row,Input} from 'reactstrap';
import './desktop.css';
import Swal from 'sweetalert2';
import Accordion from 'react-bootstrap/Accordion';
import Pasarela_santander from '../components/pasarela/pasarela';
import Pasarela_Kueski from '../components/pasarela/pasarela_Kueski';
import Mercado_pago from '../components/pasarela/pasarela_mp';
import Aplazo from '../components/pasarela/pasarela_aplazo';
import validator from 'validator'

class Modal_carrito extends Component{

    constructor() {
        super();

        this.state ={
            carrito:[],
            total:0,
            div_metodos_pago: 'visible',
            div_articulos: 'visible',
            aceptoTC:true
        }
    }

    elimina_item = (id) => {

        this.props.showModalCargando();
        let data = {
            id: id
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.props.servurlbase + '/eliminaCarrito.php', requestInfo)
            .then(response => response.json())
            .then(response => {
                    if(response){
                        this.props.getCarrito(this.props.token);
                    }
                    this.props.cierraCargando();

            })
            .catch(e => console.log(e));
    };

    showCliente = () => {
        var nombre  = document.getElementById('txtNombre').value;
        var email   = document.getElementById('txtEmail').value;
        var cemail   = document.getElementById('txtCEmail').value;
        var celular = document.getElementById('txtCelular').value;

        if ( nombre && email && celular && email === cemail) {

            if (!validator.isEmail(email)) {
                Swal.fire("Error", "Correo inválido", "warning");
                return false;
            } 

            let data = {
                token: this.props.token,
                nombre,
                email,
                celular
            }
            const requestInfo = {
                method: 'POST',
                body: JSON.stringify(data),
                header: new Headers({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                })
            };
            fetch(this.props.servurlbase + '/guardarCliente.php', requestInfo)
            .then(response => response.json())
            .then(respuesta => {

                if(respuesta){
                    document.getElementById("div_articulos").style.display = "none";
                    document.getElementById("div_metodos_pago").style.display = "none";
                    var checkedMercado = document.getElementById("MercadoPago").checked;
                    var checkedSantander = document.getElementById("Santander").checked;
                    var checkedAplazo = document.getElementById("Aplazo").checked;
                    var checkedKueski = document.getElementById("Kueski").checked;

                    if(checkedAplazo){
                        this.setState({metodoPago: '1'});
                        console.log("uno");
                    }
                    else if(checkedMercado){
                        this.setState({metodoPago: '2'});
                        console.log("dos");
                    }
                    else if(checkedSantander){
                        this.setState({metodoPago: '3'});
                        console.log("tres");
                    }
                    else if(checkedSantander){
                        this.setState({metodoPago: '3'});
                        console.log("tres");
                    }
                    else if(checkedKueski){
                        this.setState({metodoPago: '4'});
                        console.log("cuatro");
                    }
                    else{
                        console.log("else");
                    }
                }
                
            })
            .catch(e => console.log(e));
        }else{
            Swal.fire(
                'Error',
                'No puede haber campos vacíos y el correo electrónico debe coincidir',
                'warning'
            )
        }
    };

    validarTC=()=>{
        var checkedTC = document.getElementById("aceptoTC").checked;
        var checkedMercado = document.getElementById("MercadoPago").checked;
        var checkedSantander = document.getElementById("Santander").checked;
        var checkedAplazo = document.getElementById("Aplazo").checked;
        var checkedKueski = document.getElementById("Kueski").checked;
        var total = this.props.total;

        if( checkedTC && total > 0 && (checkedMercado || checkedSantander || checkedAplazo|| checkedKueski) ){
            this.setState({aceptoTC:false})
        }
        else{
            this.setState({aceptoTC:true}) 
        }
    
    };

    consultaCupones=(orden)=>{
        let data = {
            orden: orden
        }

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.props.servurlbase + '/consultaCuponesR.php', requestInfo)
        .then(response => response.json())
        .then(cuponesLS => {
            if (cuponesLS !== null) {
                this.setState({ cuponesLS });
            } else {
                this.setState({ cuponesLS: [] });
            }
        }).catch(e => console.log(e));
    }

    respuestaProducto = (data) => {
        if(data.status==="approved"){
            this.props.remplazarToken();
            this.consultaCupones(data.idOrden);
        }
    };

    imgcheck(valor) {
        document.getElementById(valor).checked = true;
        this.validarTC();
    };

    render(){
        
        return(
            <div>
                <Modal className='modal-lg' isOpen={true}>
                    <ModalHeader className='text-dark bg-light' toggle={this.props.cierra}>
                     Mi carrito
                    </ModalHeader>
                        <ModalBody className='bg-danger'>
                            <Row>
                            <div className='col-12' id='div_articulos' style={{ visibility:this.state.div_articulos}}>
                                <div className='row'>
                                 <div className='col-12'>
                                  <table className='table bg-light'>
                                   <thead>
                                     <tr>
                                        <th className='text-center'><p className="fw-bolder text-dark">Eliminar</p></th>
                                        <th className='text-center'><p className="fw-bolder text-dark">Descripión</p></th>
                                        <th className='text-center'><p className="fw-bolder text-dark">Monto</p></th>
                                     </tr>
                                    </thead>
                                    <tbody>
                            
                                    {this.props.carritoLs?this.props.carritoLs.map((objetos) =>(   

                                    <tr className='border'>
                                    <td className='text-center'>
                                        <img className='rounded-5' onClick={this.elimina_item.bind(this,objetos.id)} style={{width:'50px',cursor:'pointer'}} src={this.props.servurlassets + 'remove.png'} />
                                    </td>
                                    <td className='text-center'>
                                        <p className="fw-lighter">{objetos.descripcion}</p>
                                    </td>
                                    <td className='text-center'>
                                        <p className="fw-lighter">${objetos.monto}</p>
                                    </td>
                                  </tr>
                                    ))
                                    : 
                                    <tr className='border'>
                                     <td colSpan={3}>
                                     </td>
                                    </tr>
                                    }
                                  <tr>
                                    <td className='text-center bg-secondary bg-opacity-50' colSpan={3}>
                                    <p className="fw-bolder">Total: ${this.props.total}</p>
                                    </td>
                                  </tr>
                                  </tbody>
                                 </table>
                                </div>
                                <div className='col-sm-3 col-12 text-center'>
                                 <Input id="txtNombre" type="text" placeholder='Nombre completo'/>
                                 <br/>
                                </div>
                                <div className='col-sm-3 col-12 text-center'>
                                 <Input id="txtEmail" type="text" placeholder='Correo electrónico'/>
                                 <br/>
                                </div>
                                <div className='col-sm-3 col-12 text-center'>
                                 <Input id="txtCEmail" type="text" placeholder='Confirma Correo'/>
                                 <br/>
                                </div>
                                <div className='col-sm-3 col-12 text-center'>
                                 <Input id="txtCelular" type="text" placeholder='Celular (Whatsapp)'/>
                                 <br/>
                                </div>
                                <div className='col-12 text-center'>
                                    <br/>
                                    <button type="button" className="btn btn-link text-light" onClick={this.props.cambiaSeccion.bind(this, "tyc" , "", "")}>Acepto términos y Condiciones</button>
                                    <Input type='checkbox' id='aceptoTC' onClick={this.validarTC.bind()}/>
                                </div>
                              </div>
                            </div>

                            <div className='col-12' id='div_metodos_pago' style={{ visibility:this.state.div_metodos_pago}}>
                             <br/>
                             <Accordion defaultActiveKey="none">
                                <Accordion.Item eventKey="0">
                                <Accordion.Header>Registra tus datos para el envío de la confirmación de compra y entrega de tus cupones digitales</Accordion.Header>
                                    <Accordion.Body>
                                    <div className='row'>
                                        <div className='col-sm-4 col-12 text-center'> 
                                            <Input id="Santander" onClick={this.validarTC.bind()} type='radio' name='pago'/> 
                                            <img  onClick={this.imgcheck.bind(this,"Santander")} style={{width:'80%'}} src={this.props.servurlassets + 'mitec.png'}/>
                                            <br/>
                                        </div>
                                        <div className='col-sm-4 col-12 text-center'> 
                                            <Input id="MercadoPago" onClick={this.validarTC.bind()} type='radio' name='pago'/> 
                                            <img onClick={this.imgcheck.bind(this,"MercadoPago")} style={{width:'80%'}} src={this.props.servurlassets + 'mercadoPago.png'}/>
                                        </div>
                                        <div className='col-sm-4 col-12 text-center'> 
                                            <Input id="Aplazo" onClick={this.validarTC.bind()} type='radio' name='pago'/> 
                                            <img onClick={this.imgcheck.bind(this,"Aplazo")} className='border' style={{width:'80%'}} src={this.props.servurlassets + 'aplazo.png'}/>
                                        </div>
                                        <div className='col-sm-3 col-12 text-center' hidden={true}> 
                                            <Input id="Kueski" onClick={this.validarTC.bind()} type='radio' name='pago'/> 
                                            <img onClick={this.imgcheck.bind(this,"Kueski")} className='border' style={{width:'80%'}} src={this.props.servurlassets + 'kueski.png'}/>
                                        </div>
                                        <div className='col-12 text-center'>
                                          <br/>
                                          <button className='btn btn-secondary' disabled={this.state.aceptoTC} onClick={this.showCliente.bind()}>Pagar</button> 
                                         </div>
                                    </div>
                                    </Accordion.Body>
                                </Accordion.Item> 
                             </Accordion>
                            </div>
                                <div className='col-12 text-center'>
                                    {this.state.metodoPago=== '1'?
                                        <Aplazo servurlbase = {this.props.servurlbase} id={this.props.token} precio={this.props.total} respuestaProducto={this.respuestaProducto} carrito ={this.props.carritoLs} producto={"Producto UPA"}></Aplazo>
                                    :this.state.metodoPago=== '2'?
                                        <Mercado_pago servurlbase = {this.props.servurlbase} id={this.props.token} precio={this.props.total} respuestaProducto={this.respuestaProducto} carrito ={this.props.carritoLs} producto={"Producto UPA"}></Mercado_pago>
                                    :this.state.metodoPago=== '3'?
                                        <Pasarela_santander id={this.props.token} precio={this.props.total} producto={"Producto UPA"} respuestaProducto={this.respuestaProducto}></Pasarela_santander>
                                    :this.state.metodoPago=== '4'?
                                        <Pasarela_Kueski servurlbase = {this.props.servurlbase} id={this.props.token} precio={this.props.total} respuestaProducto={this.respuestaProducto} carrito ={this.props.carritoLs} producto={"Producto UPA"} ></Pasarela_Kueski>
                                    :''
                                    } 
                                </div>
                            </Row>
                        </ModalBody>
                        <footer className='bg-dark'>
                            <br/>
                        </footer>
                </Modal>    
            </div>
        );
    }    
}

export default Modal_carrito