import React, { Component } from "react";
import Swal from 'sweetalert2';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

class Secc_promociones extends Component {
    constructor() {
        super();

        this.state = {
          isOpen: false
        };
    }

    closeCodigoPromo = () => {
      this.setState({ isOpen: false });
    }

    showCodigoPromo = () => {
      this.setState({ isOpen: true });
    }

    agregaCarritoPromocional = () => {
      if(this.props.codigo.protipo === 'promocional'){
        this.showCodigoPromo();
      } else {
        this.agregaCarrito();
      }
    }

    validarPromo = () => {
      if(this.props.codigo.codigoPromocional === document.getElementById("codProm").value){
        this.agregaCarrito();
      }else{
        Swal.fire(
          'Error',
          'Codigo incorrecto',
          'warning'
        )
      }
    }

    agregaCarrito = () => {
        this.props.showModalCargando();

        let data = {
            token: this.props.token,
            id_promocion: this.props.codigo.idPromo,
            tipo: "cupon"
        };

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };

        setTimeout(() => {
          fetch(this.props.servurlbase + '/addCarrito.php', requestInfo)
          .then(response => response.json())
          .then(respuesta => {
              if (respuesta) {
                  this.props.getCarrito(this.props.token);
                  Swal.fire('Artículo agregado a carrito','','success');
                  this.props.cierraCargando();
              } else {
                  Swal.fire('Error','Upss, algo ocurrió, intente mas tarde','warning');
                  this.props.cierraCargando();
              }
          })
          .catch(e => console.log(e));
        }, 200);
    };

    render() {
        return (
            <div>
              <div className='container-fluid bg-warning'>
                <div className="row row_">
                  <div className="col-sm-5 col-12">
                   <br/>
                   <img className="img-fluid shadow-lg rounded-4 contenedor " src={this.props.servurlassets + this.props.codigo.nombre + this.props.codigo.extension}  />
                  </div>
                  <div className="col-sm-7 col-12">
                    <br/>
                    <h1 className="text-dark fuente">{this.props.codigo.textbold}</h1>
                    <br/><br/>
                    <h4 className="text-dark fw-bold">{this.props.codigo.title}</h4>
                    <br/>
                    <ul>
                      <li><h6 className="text-dark fw-bold">Precio ${this.props.codigo.valor}</h6></li>
                      <p>
                        Si no cuentas con tarjeta<br/>
                        El costo en UPA será de $65.00 ($40 tarjeta + $25 carga minima).
                      </p>
                    </ul>
                  </div>
                  
                  <div className="col-sm-12 col-12 text-center">
                   <button type="button" className="btn btn-light text-danger fw-bold" onClick={this.agregaCarritoPromocional} >Agregar al carrito</button>
                  </div>
                  <div className="col-sm-12 col-12 text-center">
                    <br/>
                   <a href="#" className="text-decoration-none fw-bold" onClick={this.props.cambiaSeccion.bind(this, "tyc" , "", "")}>Consulta términos y condiciones</a>
                    <br/>
                  </div>
                  <div className="col-sm-12 col-12 text-center">
                    <br/>
                    <br/>
                    <br/>
                  </div>
                </div>
              </div>
              <div>
                {/*Modal Promoción */}
                <Modal isOpen={this.state.isOpen} toggle={this.closeCodigoPromo} style={{backgroundColor:'white',maxWidth:'800px'}}>
                  <ModalHeader toggle={this.closeCodigoPromo}>Ingresa el Código Promocional</ModalHeader>
                  <ModalBody style={{backgroundColor:'#FFC107',color:'white'}}>
                    <img className="img-fluid " src={this.props.servurlassets + this.props.codigo.nombre + this.props.codigo.extension}  />
                    <label><b>Ingresa el código promocional<br/></b></label>
                    <input className="form-control" id="codProm" placeholder="Código Promocional" /><br/><br/>
                    <button type="button" className="btn btn-light text-danger fw-bold" onClick={this.validarPromo.bind()} >Agregar al carrito</button>
                  </ModalBody>
                </Modal>
              </div>
            </div>
        );
    }
}
export default Secc_promociones;
             
