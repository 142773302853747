import React, { Component } from "react";
import Modal_fiestas from "./modal_fiestas";

class Secc_fiestas extends Component {
    constructor() {
        super();

        this.state = {
            modal:false,
            sw_auto:true
        };
    }
    
    cierra = () => {
        this.setState({modal:false});
        this.setState({sw_auto:true});
    }

    showModal=() => {
        this.setState({modal:true});
        this.setState({sw_auto:false});
    }

    render() {
        return (
            <div>
              <div className='container-fluid'>
                <div className="row row_" style={{ backgroundImage: `url("https://ecommercegd.blob.core.windows.net/imagenes-u/secc_fiestas_back.png")` , backgroundSize:'100%'}}>
                  <div className="col-sm-4 col-12 text-center">
                   <img className="img-fluid" src={this.props.servurlassets + "secc_fiestas_img5.png"} />
                   <img className="img-fluid" src={this.props.servurlassets + "icono_upa.png"} />
                  </div>
                  <div className="col-sm-8 col-12 text-center">
                   <br/>
                   <img className="img-fluid" src={this.props.servurlassets + "fiestas_upa.png"} />
                  </div>
                  <div className="col-sm-12 col-12 text-center">
                   <br/>
                   <button className="btn btn-light text-danger fw-bold" onClick={this.showModal.bind()}>COTIZAR FIESTA</button>
                  </div>
                  <div className="col-sm-12 col-12 text-center">
                   <br/>
                   <br/>
                  </div>
                </div>
              </div>
                 {
                  this.state.modal === true?
                    <Modal_fiestas cierra = {this.cierra} servurlassets = {this.props.servurlassets} servurlbase = {this.props.servurlbase} />
                  :''
                 }
            </div>
        );
    }
}
export default Secc_fiestas;
