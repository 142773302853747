let socket;
export const initiateSocket = (room, urlsocket) => {
    //socket = io(urlsocket);
    socket = new WebSocket(urlsocket, 'json.webpubsub.azure.v1');
    if (socket && room) {
        let ackId = 0;
        socket.onopen = () => {
            socket.send(JSON.stringify({
                type: 'joinGroup',
                group: room,
                ackId: ++ackId
            }));
        };


    }
}
export const disconnectSocket = () => ({
    //if (socket) socket.disconnect();
});

export const subscribeToChat = (cb) => {
    if (!socket) return (true);
        socket.onmessage = event => {
        let message = JSON.parse(event.data);

        if(message.data){
            var order       = message.data.OrdenId;
            var status      = message.data.Estatus; 
            var mensaje     = message.data.Mensaje;
            var idOrden     = message.data.StrCifrado;
        }
        

        var response = new Object();
        response.order = order;
        response.status = status;
        response.errorCode = mensaje;
        response.idOrden = idOrden;
            
        return cb(null, response);

    };
}
