import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Main from './main';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

//estilos y cookies
//npm install bootstrap --save 
//npm install universal-cookie
//npm install react-bootstrap bootstrap
//npm install reactstrap bootstrap
//npm install --save @react-google-maps/api

//nav movil
//npm install --save react-offcanvas

//npm install react-multi-carousel --save

//archivos .env
//yarn add env-cmd

/*package-json:
"scripts": {
    "start": "env-cmd -f .env.dev react-scripts start",
    "build:qa": "env-cmd -f .env.qa react-scripts build && rm -rf pubqa && mv build pubqa",
    "build:prod": "env-cmd -f .env.prod react-scripts build && rm -rf pubprod && mv build pubprod",
    "test": "react-scripts test",
    "eject": "react-scripts eject"
  },
*/

//npm install --save sweetalert2 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);

reportWebVitals();
