import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody,Row,Input} from 'reactstrap';
import './desktop.css';

class Modal_compara_combos extends Component{

    constructor() {
        super();

        this.state ={
            
        }

    }


    render(){
        
        return(
            <div>
                <Modal className='modal-lg' isOpen={true}>
                    <ModalHeader className='bg-light'  toggle={this.props.cierraCompara}>
                    </ModalHeader>
                        <ModalBody className='bg-warning'>
                          <Row> 
                             <img className="img-fluid" src={this.props.servurlassets + "Combos_buenos.jpg"}  />
                          </Row>
                        </ModalBody>
                        <footer className='bg-dark'>
                            <br/>
                        </footer>
                </Modal>    
            </div>
        );
    }    
}

export default Modal_compara_combos